import * as z from 'zod';
import { TranslationFunction } from '@core-systems/i18n';

export function LoginFormSchema(t: TranslationFunction): z.ZodSchema {
  return z.object({
    email: z
      .string()
      .min(1, {
        message: t('login.form.fields.email.errors.required'),
      })
      .email(t('login.form.fields.email.errors.email')),
    password: z.string().min(1, {
      message: t('login.form.fields.password.errors.required'),
    }),
  });
}

export type LoginFormSchema = z.infer<ReturnType<typeof LoginFormSchema>>;
