'use client';
import { useSearchParams } from 'next/navigation';
import { useClientTranslations } from '@core-systems/i18n';
import { isInDomain } from '@core-systems/utils';
import { Link } from '@design-system';
import { i18nNamespace } from '../../../../shared/i18n-namespaces';

export type RegisterLinkProps = { safeDomain: string };

export function RegisterLink({ safeDomain }: RegisterLinkProps): React.JSX.Element {
  const searchParams = useSearchParams();
  const { t } = useClientTranslations(i18nNamespace.AUTH);

  // Make sure that the registerUri is on the safe domain
  const registerUri = searchParams.get('registerUri');

  if (!registerUri) {
    return <></>;
  }

  const safeRegisterUri = registerUri && isInDomain(registerUri, safeDomain) ? registerUri : undefined;
  return (
    <p className="mb-ms body-4 sm:body-3 mx-auto w-fit uppercase">
      {t('login.form.cta.no-account.description')}{' '}
      <Link href={safeRegisterUri!} prefetch={false}>
        {t('login.form.cta.no-account.label')}
      </Link>
    </p>
  );
}
