export function matchErrorCodeToTranslationKey(errorCode: string = ''): string {
  switch (errorCode) {
    case 'InvalidCredentials':
      return 'login.form.cta.submit.errors';
    case 'InvalidAuthorizationCode':
      return 'login.form.cta.google.errors';
    case 'UnhandledServerError':
    case 'MissingDataInAuthResponseError':
    default:
      return 'errors.default';
  }
}
