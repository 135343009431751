'use client';
import React, { useCallback, useEffect, useState } from 'react';
import Script from 'next/script';
import { signIn } from 'next-auth/react';
import { useClientLocale, useClientTranslations } from '@core-systems/i18n';
import { Button, GoogleIcon, useToast } from '@design-system';
import { i18nNamespace } from '../../../../shared/i18n-namespaces';
import { matchErrorCodeToTranslationKey } from './match-error-code-to-translation-key';

export type LoginWithGoogle = { googleClientId: string };

export function LoginWithGoogle({ googleClientId }: LoginWithGoogle): React.JSX.Element {
  const { t } = useClientTranslations(i18nNamespace.AUTH);
  const { locale } = useClientLocale();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLogging, setIsLogging] = useState(false);
  const { toast } = useToast();

  // At the first render, check if the google object is already loaded
  useEffect(() => {
    const google = window.google;
    if (google) {
      setIsLoaded(true);
    }
  }, []);

  const login = useCallback(() => {
    const google = window.google;
    if (!isLoaded || !google) {
      return (): void => {
        // do nothing
      };
    }
    setIsLogging(true);

    /**
     * Documentation for the following function
     * https://developers.google.com/identity/oauth2/web/reference/js-reference#google.accounts.oauth2.initTokenClient
     */
    const client = google.accounts.oauth2.initTokenClient({
      client_id: googleClientId,
      scope: 'openid',
      callback: async (response): Promise<void> => {
        try {
          if (response.error) {
            throw new Error(response.error);
          }
          const result = await signIn('google', {
            accessToken: response.access_token,
            redirect: false,
          });
          if (result?.error) {
            const translationKey = matchErrorCodeToTranslationKey(result?.code || '');
            toast({
              variant: 'error',
              title: t(`${translationKey}.title`),
              description: t(`${translationKey}.description`),
            });
            setIsLogging(false);
          }
        } catch (error) {
          setIsLogging(false);
          toast({
            variant: 'error',
            title: t('login.form.cta.google.errors.title'),
            description: t('login.form.cta.google.errors.description'),
          });
        }
      },
      error_callback: (error): void => {
        // User closed the popup
        setIsLogging(false);
      },
    });
    client.requestAccessToken({ prompt: '' });
  }, [isLoaded, toast, t, googleClientId]);

  useEffect(() => {
    const google = window.google;
    if (!isLoaded || !google) {
      return;
    }
    /**
     * Documentation for the following function
     * https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.initialize
     */
    google.accounts.id.initialize({
      client_id: googleClientId,
      auto_select: true,
    });
  }, [isLoaded, googleClientId]);

  if (!googleClientId) {
    return <p className="text-error">Google client not configured</p>;
  }
  return (
    <>
      <Script
        src={`https://accounts.google.com/gsi/client?hl=${locale}`}
        async
        defer
        onLoad={() => setIsLoaded(true)}
      />
      <Button
        variant="secondary"
        className="w-full max-w-full"
        loading={!isLoaded || isLogging}
        onClick={login}
        Icon={GoogleIcon}
      >
        {t('login.form.cta.google')}
      </Button>
    </>
  );
}
