'use client';

import { useSearchParams } from 'next/navigation';
import { useClientTranslations } from '@core-systems/i18n';
import { withSearchParams } from '@core-systems/utils';
import { Link } from '@design-system';
import { i18nNamespace } from '../../../../shared/i18n-namespaces';

export function ForgotPasswordLink(): React.JSX.Element {
  const searchParams = useSearchParams();
  const { t } = useClientTranslations(i18nNamespace.AUTH);

  // Make sure to preserve the query params while going to forgot-password page
  const forgotPasswordUri = withSearchParams('forgot-password', searchParams);

  return (
    <p className="body-4 sm:body-3 mx-auto w-fit uppercase">
      <Link href={forgotPasswordUri} className="font-medium" prefetch={true}>
        {t('login.form.cta.forgot-password')}
      </Link>
    </p>
  );
}

export function ForgotPasswordLinkFallback(): React.JSX.Element {
  const { t } = useClientTranslations(i18nNamespace.AUTH);

  return (
    <p className="body-4 sm:body-3 mx-auto w-fit uppercase">
      <Link href="forgot-password" className="font-medium">
        {t('login.form.cta.forgot-password')}
      </Link>
    </p>
  );
}
