'use client';

import React, { Suspense, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { signIn } from 'next-auth/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  isFormInvalid,
  Switch,
  useToast,
} from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { i18nNamespace } from '../../../../shared/i18n-namespaces';
import { LoginFormSchema } from './login-form.schema';
import { RegisterLink } from './register-link';
import { ForgotPasswordLink, ForgotPasswordLinkFallback } from './forgot-password-link';
import { matchErrorCodeToTranslationKey } from './match-error-code-to-translation-key';

export type LoginWithCredentialsProps = { safeDomain: string };

export function LoginWithCredentials({ safeDomain }: LoginWithCredentialsProps): React.JSX.Element {
  const [showPassword, setShowPassword] = useState(false);
  const [isLogging, setIsLogging] = useState(false);

  const { t } = useClientTranslations(i18nNamespace.AUTH);
  const { toast } = useToast();

  const form = useForm<LoginFormSchema>({
    resolver: zodResolver(LoginFormSchema(t)),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  async function handleLogin(data: LoginFormSchema): Promise<void> {
    setIsLogging(true);

    const result = await signIn('credentials', {
      email: data.email,
      password: data.password,
      redirect: false,
    });

    if (result?.error) {
      const translationKey = matchErrorCodeToTranslationKey(result?.code || '');

      toast({
        variant: 'error',
        title: t(`${translationKey}.title`),
        description: t(`${translationKey}.description`),
      });

      setIsLogging(false);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleLogin)}>
        <FormField
          control={form.control}
          name="email"
          render={({ field }: { field: FieldValues }) => (
            <FormItem>
              <FormLabel>{t('login.form.fields.email.label')}</FormLabel>
              <FormControl>
                <Input
                  data-testid="login-email"
                  placeholder={t('login.form.fields.email.placeholder')}
                  type="text"
                  autoComplete="email"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }: { field: FieldValues }) => (
            <FormItem className="mt-lg">
              <FormLabel>{t('login.form.fields.password.label')}</FormLabel>
              <FormControl>
                <Input
                  data-testid="login-pwd"
                  type={showPassword ? 'text' : 'password'}
                  placeholder={showPassword ? '' : t('login.form.fields.password.placeholder')}
                  autoComplete="current-password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="gap-md mt-lg flex flex-row items-center">
          <Switch id="show-password" checked={showPassword} onClick={() => setShowPassword(!showPassword)}></Switch>
          <label htmlFor="show-password">{t('login.form.cta.show-password')}</label>
        </div>
        <Button
          data-testid="login-submit"
          disabled={isFormInvalid(form)}
          loading={isLogging}
          type="submit"
          className="mb-lg mt-xxl w-full"
        >
          {t('login.form.cta.submit')}
        </Button>
        <Suspense>
          <RegisterLink safeDomain={safeDomain} />
        </Suspense>
        <Suspense fallback={<ForgotPasswordLinkFallback />}>
          <ForgotPasswordLink />
        </Suspense>
      </form>
    </Form>
  );
}
