'use client';
import React, { useEffect, useState } from 'react';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { isInDomain, withSearchParams } from '@core-systems/utils';
import { useClientTranslations } from '@core-systems/i18n';
import { useToast } from '@design-system';
import { i18nNamespace } from '../../../../shared/i18n-namespaces';

export function SessionChecker({ safeDomain }: { safeDomain: string }): React.JSX.Element {
  const { t } = useClientTranslations(i18nNamespace.AUTH);
  const { toast } = useToast();
  const router = useRouter();
  const { data: session, status } = useSession();
  const [cookieDeleted, setCookieDeleted] = useState(false);

  useEffect(() => {
    if (status === 'loading' || !session) {
      return;
    }
    if (session.isValid) {
      const searchParams = new URLSearchParams(window.location.search);
      const redirectUri = searchParams.get('redirectUri');
      const fallbackRedirectUri = searchParams ? withSearchParams('login/success', searchParams) : 'login/success';

      // Make sure that the redirectUri is on the safe domain
      const safeRedirectUri = redirectUri && isInDomain(redirectUri, safeDomain) ? redirectUri : fallbackRedirectUri;
      router.push(safeRedirectUri);
    }
    if (session.isExpired && !cookieDeleted) {
      toast({
        variant: 'error',
        title: t('errors.session-expired.title'),
        description: t('errors.session-expired.description'),
      });
      // Delete the cookie so that we display the toast only once
      signOut({ redirect: false });
      setCookieDeleted(true); // fix an infinite loop due to next-auth not updating the session variable on signOut
    }
  }, [router, safeDomain, t, toast, session, status, cookieDeleted]);

  return <></>;
}
